<template>
  <div class="pre-mint">
    <div class="pre-mint__banner">
      <img
        class="pre-mint__banner-img"
        src="../assets/img/form_ape.jpeg"
        alt="ape-event"
      >
      <div class="pre-mint__banner-overlay" />
    </div>
    <div class="pre-mint__content">
      <div class="pre-mint__info">
        <h2 class="pre-mint__info-subtitle">
          Pre-Mint Sold Out
        </h2>
        <button class="pre-mint__btn center">
          <a href="https://mint.bullsandapesproject.com/list-ape-mint">Allowlist for February Mint</a>
        </button>
        <h1>THE APE INVASION HAS BEGUN!!</h1>

        <div class="pre-mint__info-details">
          <p><span class="underline">LIMITED</span> # OF MINTS</p>
          <p><span class="underline">LIMITED</span> AMOUNT OF TIME</p>
        </div>

        <div
          ref="mintActions"
          class="pre-mint__info-cta"
        >
          <p>DON’T MISS THIS OPPORTUNITY!</p>

          <button class="pre-mint__btn center">
            <a href="https://mint.bullsandapesproject.com/list-ape-mint">Allowlist for February Mint</a>
          </button>
          <p class="pre-mint__info-cta-disclaimer">
            DISCLAIMER: Portal Pass must be redeemed during the allocated
            redemption window of our Official Mint Feb 7th
          </p>
        </div>
      </div>

      <!--<h3 class="pre-mint__info-title">Allow List Details</h3>-->

      <div class="pre-mint__boxes">
        <div
          v-for="tier in tiers"
          :key="tier.name"
          class="pre-mint__box outlined"
        >
          <div class="pre-mint__box-header">
            <h4 class="pre-mint__box-title">
              {{ tier.title }}
            </h4>
            <p class="pre-mint__box-price">
              {{ tier.price.toFixed(2) }} ETH
            </p>
          </div>
          <p class="pre-mint__box-description">
            {{ tier.description }}
          </p>
        </div>
      </div>

      <div class="pre-mint__shop">
        <h2>Do You Own A Bull Yet ??</h2>
        <p>
          You’ve seen the value of OG & Teen Bulls skyrocket as we continue to
          deliver
        </p>
        <p>
          What do you think will happen when the word gets out about Apes?!?
        </p>

        <div class="pre-mint-opensea-container">
          <a
            class="pre-mint-opensea"
            target="_blank"
            href="https://opensea.io/collection/bullsandapes-genesis"
          >
            <div>
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
                alt="opensea"
              >
            </div>
            <span>OG Bulls</span>
          </a>
          <a
            class="pre-mint-opensea"
            target="_blank"
            href="https://opensea.io/collection/bullsandapes-teenbulls"
          >
            <img
              src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
              alt="opensea"
            >
            <span>Teen Bulls</span>
          </a>
        </div>
      </div>

      <StartPageNewsLetterVue />

      <div class="pre-mint__faqs">
        <h2 class="pre-mint__faqs-title">
          Frequently Asked Questions
        </h2>
        <div class="pre-mint__faqs-container">
          <h3 class="pre-mint__faqs-subtitle">
            Mint Questions
          </h3>

          <button
            v-for="faq in faqs.mint"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>

          <h3 class="pre-mint__faqs-subtitle">
            Ape Questions
          </h3>

          <button
            v-for="faq in faqs.apes"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>

          <h3 class="pre-mint__faqs-subtitle">
            Dynamic NFT & Traits Questions
          </h3>

          <button
            v-for="faq in faqs.nfts"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>
        </div>
      </div>

      <div class="pre-mint__footer">
        <div class="pre-mint-opensea-container">
          <a
            href="https://opensea.io/assets/ethereum/0x389dcc122a2304a95ef929e71b7ef2504c6cc3ac/1"
            target="_blank"
            rel="noopener noreferrer"
            class="pre-mint-opensea"
          >
            <div>
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
                alt="opensea"
              >
            </div>
            <span>Portal Pass</span>
          </a>
        </div>
      </div>
      <div class="pre-mint__content-overlay" />
    </div>
  </div>
</template>

<script>
import StartPageNewsLetterVue from "../components/StartPage/StartPageNewsLetter.vue";
import PAGE_DATA from "../config/pre_mint_data.json";

// import axios from "axios";

const { faqs, page_data, tiers } = PAGE_DATA;
let saleInfo = {
  minted: 0,
  maxSupply: 0,
  maxPerTx: 0,
  maxPerWallet: 0,
  price: 0,
  status: 0,
  uniqueCount: 0,
};
let userInfo = {
  alreadyMint: 0,
  position: 0,
  isWhitelisted: false,
};
let mintAmount = 1;
let activeBonus = [];
let isMintingEnabled = false;

const mint = faqs.mint.map((faq) => ({
  ...faq,
  open: false,
  active: false,
}));

const apes = faqs.apes.map((faq) => ({
  ...faq,
  open: false,
}));

const nfts = faqs.nfts.map((faq) => ({
  ...faq,
  open: false,
}));

export default {
  name: "PreMint",
  components: {
    StartPageNewsLetterVue,
  },
  props: {
    handleBuyTickets: {
      type: Function,
    },
  },
  data() {
    return {
      saleInfo,
      userInfo,
      mintAmount,
      activeBonus,
      isMintingEnabled,
      faqs: {
        mint,
        apes,
        nfts,
      },
      pre: page_data.pre,
      fast: page_data.fast,
      main: page_data.main,
      tiers,
    };
  },
  computed: {
    isConnected: function () {
      return false;
      // const walletAddress = useWeb3Store().walletAddress;

      // return walletAddress;
    },
  },

  methods: {
    async connectWallet() {
      await this.connect(true);
    },

    maxAmount() {
      this.mintAmount = this.saleInfo.maxPerTx - this.userInfo.alreadyMint;
    },
    increment() {
      if (
        this.mintAmount <
        this.saleInfo.maxPerTx - this.userInfo.alreadyMint
      ) {
        this.mintAmount++;
      } else {
        this.mintAmount = this.saleInfo.maxPerTx - this.userInfo.alreadyMint;
      }
    },
    handleFooterMintButtonClick() {
      window.scrollTo({
        top: 350,
        behavior: "smooth",
      });
    },
    decrement() {
      if (this.mintAmount > 1) {
        this.mintAmount--;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.page-wrapper {
  padding-top: 0 !important;
}

body {
  background: #0f0612;
}

.BAP-header {
  display: none;
}
.header-announcement {
  display: none !important;
}

@media screen and (min-width: 1150px) {
  .BAP-header {
    background: transparent !important;
    justify-content: center !important;
  }

  .header-announcement {
    display: none;
  }

  .BAP-header__logo {
    display: none;
  }

  .BAP-header__socials {
    display: none;
  }

  .BAP-header__nav {
    padding: 0.5rem;
    background: hsla(291, 48%, 11%, 0.3);
    border-radius: 0.5rem;
    /* background filter blur */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
}

.start-page-news-letter {
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  background: #28323e71;
  width: min(calc(100% - 8rem), 500px);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.start-page-news-letter-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
}

.start-page-news-letter input {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #fff;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.start-page-news-letter button {
  background: #ab1efe;
  border: 1px solid #ab1efe;
  color: #fff;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.start-page-news-letter-title {
  display: none;
}

.start-page-news-letter-bg {
  /* background: none; */
  background: url("../assets/img/Bulls/ApeMale12_Main.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: color;
}

@media screen and (max-width: 768px) {
  .start-page-news-letter {
    width: calc(100% - 4rem);
    height: auto;
  }
}

.footer {
  margin: 0;
}
</style>
